// Import the functions you need from the SDKs you need
import * as Type from "../Utility/typeDef";
import { ResumeBuilderModel } from "../model/ResumeBuilderModel";
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updatePassword,
  confirmPasswordReset,
  sendEmailVerification,
  applyActionCode,
  signOut,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  startAfter,
  limit,
  startAt,
  orderBy,
  deleteDoc,
} from "firebase/firestore/lite";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { da } from "date-fns/locale";

//Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9A6_4INMSymDFgJj6lp3EQ7D7kWdUznU",
  authDomain: "magic-cv-c4fba.firebaseapp.com",
  projectId: "magic-cv-c4fba",
  storageBucket: "magic-cv-c4fba.appspot.com",
  messagingSenderId: "1083387618525",
  appId: "1:1083387618525:web:83eb20a8324bdf24d46734",
  measurementId: "G-0S3RTHF9GR"
};

//buildCV

// const firebaseConfig = {
//   apiKey: "AIzaSyD_8ZqGyi7If3A-jBlTLAnvCnJfRINyerw",
//   authDomain: "buildcv-vntechsolution.firebaseapp.com",
//   projectId: "buildcv-vntechsolution",
//   storageBucket: "buildcv-vntechsolution.appspot.com",
//   messagingSenderId: "378403052710",
//   appId: "1:378403052710:web:b5422e66e41c7ce8e16867",
//   measurementId: "G-D6DH0HC9GE"
// };


// const firebaseConfig = {
//   apiKey: "AIzaSyD_8ZqGyi7If3A-jBlTLAnvCnJfRINyerw",
//   authDomain: "buildcv-vntechsolution.firebaseapp.com",
//   projectId: "buildcv-vntechsolution",
//   storageBucket: "buildcv-vntechsolution.appspot.com",
//   messagingSenderId: "378403052710",
//   appId: "1:378403052710:web:b5422e66e41c7ce8e16867",
//   measurementId: "G-D6DH0HC9GE"
// };

// Development firebase
// const firebaseConfig = {
//   apiKey: "AIzaSyDolh_iS6XBViUot_1GoOZhKpWJtSMop5E",
//   authDomain: "fir-70e23.firebaseapp.com",
//   databaseURL: "https://fir-70e23.firebaseio.com",
//   projectId: "fir-70e23",
//   storageBucket: "fir-70e23.appspot.com",
//   messagingSenderId: "508702344870",
//   appId: "1:508702344870:web:6a773bb39f1439e07d9667",
// };

// Initialize Firebase
const fire = initializeApp(firebaseConfig);
export default fire;

// Initialize Firebase
const analytics = getAnalytics(fire);

const db = getFirestore(fire);

export const auth = getAuth();

const storage = getStorage();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = (callback) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      callback?.(false, "", { user, token });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      callback?.(true, errorCode);
      // ...
    });
};

export const CreateUserWithEmailAndPassword = (email, password, callback) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      let user = userCredential.user;
      let token = userCredential._tokenResponse;
      callback?.(false, "AccountCreatedSuccessMsg", { user, token });
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === "auth/email-already-in-use") {
        callback?.(true, "EmailAlreadyInUse");
      } else if (errorCode === "auth/weak-password") {
        callback?.(true, "PasswordWeak");
      } else {
        console.log(errorCode);
        callback?.(true, "Opps");
      }

      // ..
    });
};

export const SignInWithEmailAndPassword = (email, password, callback) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      let user = userCredential.user;
      let token = userCredential._tokenResponse;
      callback?.(false, "", { user, token });
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      callback?.(true, errorCode);
    });
};

export const SendEmailVerification = async() => {
  sendEmailVerification(auth.currentUser)
    .then(() => {})
    .catch((error) => {
      console.log(error)
    });
};

export const CheckUserStatus = async (funCallback) => {
  //const user = auth.currentUser;
  await onAuthStateChanged(auth, funCallback);
};

export const SendPasswordResetEmail = (email, funCallback) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      funCallback?.(false, "");
    })
    .catch((error) => {
      //auth/user-not-found
      if (error.code == "auth/user-not-found") {
        funCallback?.(true, "UserNotFound");
      }
      else if (error.code == "auth/network-request-failed") {
        funCallback?.(true, "UserNotFound");
      } else {
        funCallback?.(true, error.message);
      }
    });
};

export const UpdatePassword = (newPassword, funCallback) => {
  updatePassword(auth.currentUser, newPassword)
    .then(() => {
      funCallback?.(false, "");
    })
    .catch((error) => {
      
     if (error.code == "auth/network-request-failed") {
        funCallback?.(true, "NetworkRequestFailed");
      }
      else{
        funCallback?.(true, error.message);
      }
      
    });
};

export const ConfirmPasswordReset = (oobCode, newPassword, funCallback) => {
  confirmPasswordReset(auth, oobCode, newPassword)
    .then(() => {
      funCallback?.(false, "");
    })
    .catch((error) => {
      if (error.code == "auth/expired-action-code") {
        funCallback?.(true, "ResetLinkExpire");
      }
      if (error.code == "auth/invalid-action-code") {
        funCallback?.(true, "InvalidAction");
      } else {
        funCallback?.(true, error.message);
      }
    });
};

export const ApplyActionCode = (oobCode, callback) => {
  applyActionCode(auth, oobCode)
    .then(() => {
       callback?.(false, "");
    })
    .catch((error) => {
      //auth/invalid-action-code
     if (error.code == "auth/invalid-action-code") {
       callback?.(true, "InvalidAction");
     } else {
       callback?.(true, error.message);
     }
    });
};

export const SignOut = (funCallback) => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      funCallback?.(true, "");
    })
    .catch((error) => {
      // An error happened.
      funCallback?.(false, error.message);
    });
};

export const getAllResumeTemplate = async (getAll) => {
  let allTemplate = [];
  try {
    const resumeCollection = collection(db, "ResumeTemplate");
    let q = query(
      resumeCollection,
      where("Status", "==", "publish"),
      where("Deleted","==",false)
    );

    if(getAll != null && getAll == true)
    {
      q = query(resumeCollection, where("Deleted", "==", false));
    }  

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => {
      allTemplate.push({ ...doc.data(), Id: doc.id });
    });
  } catch (error) {
    console.log(error)
  }

  return allTemplate;
};

export const addUserResume = async (data, callback) => {
  try {
    debugger;
    const docRef = await addDoc(collection(db, "UserResume"), data);
    callback && callback(false, "ResumeSaveSuccessfullyMessage", docRef.id);
  } catch (error) {
    callback && callback(true, error);
  }
};

export const updateUserResume = async (docId, data, callback) => {
  try {
    debugger;
    const docRef = doc(db, "UserResume", docId);
    await updateDoc(docRef, data);
    callback && callback(false, "ResumeUpdateSuccessfullMessage");
  } catch (error) {
    callback && callback(true, error);
  }
};

export const getUserAllResume = async (userId, callback) => {
  let allTemplate = [];
  try {
    const resumeCollection = collection(db, "UserResume");
    const q = query(resumeCollection, where("UserId", "==", userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => {
      allTemplate.push({ ...doc.data(), Id: doc.id });
    });
  } catch (error) {
    callback && callback(true, error);
  }

  return allTemplate;
};

export const getUser = async (docId, callback) => {
  try {
    const docRef = doc(db, "User", docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (error) {}
};

export const addUser = async (docId, data, callback) => {
  try {
    await setDoc(doc(db, "User", docId), data);
    callback && callback(false, "");
  } catch (error) {
    callback && callback(true, "");
  }
};

export const publishTemplate = async (docId, data, callback) => {
  try {
    const docRef = doc(db, "ResumeTemplate", docId);
    await updateDoc(docRef, data);
    callback && callback(false, "PublishSuccessMsg");
  } catch (error) {
    callback && callback(true, error);
  }
};

export const unpublishTemplate = async (docId, data, callback) => {
  try {
    const docRef = doc(db, "ResumeTemplate", docId);
    await updateDoc(docRef, data);
    callback && callback(false, "UnpublishSuccessMsg");
  } catch (error) {
    callback && callback(true, error);
  }
};

export const addTemplate = async (data, callback) => {
  try {
    const docRef = await addDoc(collection(db, "ResumeTemplate"), data);
    callback && callback(false, "TemplateSavedSuccessfully", docRef.id);
  } catch (error) {
    callback && callback(true, error);
  }
};

export const updateTemplate = async (docId, data, callback) => {
  try {
    const docRef = doc(db, "ResumeTemplate", docId);
    await updateDoc(docRef, data);
    callback && callback(false, "TemplateUpdatedSuccessfully");
  } catch (error) {
    callback && callback(true, error);
  }
};

export const deleteUserResume = async(docId,callback)=>{
  try{
    await deleteDoc(doc(db, "UserResume", docId));
     callback && callback(false, "DeleteSuccessMsg");
  }
  catch(error){
     callback && callback(true, error);
  }
}

export const addFeedback = async (data, file,callback) => {
  try {
    const docRef = await addDoc(collection(db, "UserFeedback"), data);
    // 'file' comes from the Blob or File API
    if (file){
      let temp = ref(storage, data.ImagePath);
      uploadBytes(temp, file[0]).then((snapshot) => {
        console.log(JSON.stringify(snapshot))
        console.log("Uploaded a blob or file!");
      });
    }
      
    callback && callback(false, "FeedbackSuccessMsg");
  } catch (error) {
    callback && callback(true, error);
  }
};

export const getFeedbacks = async(searchValue,searchCriteria,lastObject,callback)=>{
  try{
    let result =[]
    const docRef = collection(db, "UserFeedback");
    let q;
    const  queryConstraint = []
    

    q = query(docRef, ...queryConstraint, limit(10));
    if(searchValue !="")
    {
       queryConstraint.push(where("User.Name", ">=", searchValue));
    }

    //searchCriteria 0 = all, 1 = read, 2=unread 
    if(searchCriteria == 1)
    {
       queryConstraint.push(where("Read", "==", true))
    }

    
    if(searchCriteria == 2)
    {
       queryConstraint.push(where("Read", "==", false));
    }
    
    if (lastObject){
      queryConstraint.push(startAfter(lastObject));
    }
    
    q = query(docRef, ...queryConstraint, limit(10));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => {
      result.push({ ...doc.data(), Id: doc.id });
    });
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    callback && callback(false, "", result, lastVisible);
  }
  catch(error){
    callback && callback(true, error);
  }
}

export const updateFeedbackStatus = async (docId, data, callback) => {
  try {
    const docRef = doc(db, "UserFeedback", docId);
    await updateDoc(docRef, data);
  } catch (error) {
  }
};

export const filePath = async(fileName,callback)=>{
  const storage = getStorage();
   let temp = ref(storage, fileName);  
   getDownloadURL(temp)
  .then((url) => {

    callback && callback(false,"",url)
  })
  .catch((error) => {
    callback && callback(true,error.message)
  });
}


